<!-- 账户充值 -->
<template>
  <div class="recharge">
    <div class="top">
      <span class="recode" @click="to_recode()">充值记录</span>
      <p class="title">账户余额</p>
      <div class="price" v-if="balance > 0">
        ￥<span>{{ balance }}</span>
      </div>
      <div class="price" v-else><span>0</span></div>
    </div>
    <div class="money">
      <p class="tip">请选择充值金额</p>
      <ul class="money-list">
        <li
          class="money-item"
          :class="item == money ? 'active' : ''"
          v-for="(item, index) in money_list"
          :key="index"
          @click="change_money(item)"
        >
          {{ item }}元
        </li>
      </ul>
    </div>
    <div class="bottom">
      <div class="user d-flex">
        <van-checkbox
          class="check"
          v-model="checked"
          shape="square"
          icon-size="12px"
        >
          <template #icon="props">
            <img
              class="img-icon"
              :src="props.checked ? activeIcon : inactiveIcon"
            />
          </template>
        </van-checkbox>
        勾选后代表您已阅读并同意<span @click="to_user()">《用户协议》</span>
      </div>
      <button class="btn" v-if="checked" @click="pay()">确认支付</button>
      <button class="btn disable" v-else disabled>确认支付</button>
    </div>
  </div>
</template>


<script>
import { getData, postData } from "@/api/user";
import { _debounce } from "@/libs/util";
import wx from "weixin-js-sdk";

export default {
  data() {
    return {
      balance: 0,
      money: 30,
      money_list: [30, 50, 100],
      checked: false,
      activeIcon:
        "https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/20210625155336.png",
      inactiveIcon:
        "https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/20210625155757.png",
    };
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#fff");
  },
  created() {
    this.get_balance();
  },
  methods: {
    // 账户余额
    get_balance() {
      getData({
        url: "api/charging/user/user_balance",
        data: {},
      }).then((res) => {
        let data = res.data.data;
        if (res.data.code === 200) {
          this.balance = data.data || 0;
        } else if (res.data.code === 422) {
          this.$toast(res.data.data.msg);
        }
      });
    },
    to_recode() {
      this.$router.push({ name: "recharge_recode" });
    },
    to_user() {
      this.$router.push({ name: "deal" });
    },
    change_money(item) {
      this.money = item;
    },
    pay: _debounce(function () {
      let that = this;
      postData({
        url: "api/charging/consume/payCz",
        data: {
          money: this.money,
        },
      }).then((res) => {
        let pay_data = res.data;
        console.log("pay_res", res, pay_data);

        getData({
          url: "api/get_js_config",
          data: {
            url: window.location.href.split("#")[0],
          },
        }).then((res) => {
          let data = res.data.data;
          wx.config({
            debug: false,
            appId: data.appId,
            timestamp: data.timestamp,
            nonceStr: data.nonceStr,
            signature: data.signature,
            jsApiList: ["checkJsApi", "chooseWXPay"],
          });
          wx.error(function (res) {
            alert("出错了：" + res.errMsg); //这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
          });

          wx.ready(function () {
            wx.checkJsApi({
              // 判断当前客户端版本是否支持指定JS接口
              jsApiList: ["chooseWXPay"],
              success: function (res) {
                // 以键值对的形式返回，可用true，不可用false。如：{"checkResult":{"scanQRCode":true},"errMsg":"checkJsApi:ok"}
                if (res.checkResult.chooseWXPay === true) {
                  wx.chooseWXPay({
                    appId: pay_data.appId,
                    timestamp: pay_data.timestamp,
                    nonceStr: pay_data.nonceStr,
                    package: pay_data.package,
                    signType: pay_data.signType,
                    paySign: pay_data.paySign,
                    prepayId: pay_data.prepayId,
                    success: function () {
                      that.$toast("支付成功");
                      that.$router.push({ name: "user" });
                    },
                    fail() {
                      that.$toast("支付失败");
                    },
                  });
                } else {
                  alert("抱歉，当前客户端版本不支持");
                }
              },
              fail: function (res) {
                // 检测getNetworkType该功能失败时处理
                alert("检测getNetworkType该功能失败" + res);
              },
            });
          });
          /* 处理失败验证 */
          wx.error(function (res) {
            // config 信息验证失败会执行error函数,如签名过期导致验证失败,具体错误信息可以打开config的debug模式查看,也可以在返回的res参数中查看,对于SPA可以在这里更新签名
            alert("配置验证失败: " + res.errMsg);
          });
        });

        if (res.data.code === 422) {
          this.$toast(res.data.data.msg);
        }
      });
    }),
  },
  beforeRouteEnter(to, from, next) {
    var u = navigator.userAgent;

    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

    // XXX: 修复iOS版微信HTML5 History兼容性问题

    if (isiOS && to.path !== location.pathname) {
      // 此处不可使用location.replace

      location.assign(to.fullPath);
    } else {
      next();
    }
  },
};
</script>

<style lang="less">
.recharge {
  .top {
    background: #fff
      url(https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/%E8%92%99%E7%89%88%E7%BB%84%208%403x.png)
      no-repeat top left;
    background-size: 100%;
    width: 100%;
    height: 230px;
    padding: 68px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .recode {
    position: absolute;
    top: 32px;
    right: 12px;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    color: #f4f4f4;
    text-decoration: underline;
  }
  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #fff;
    margin-bottom: 30px;
  }
  .price {
    font-size: 24px;
    font-weight: bold;
    line-height: 48px;
    color: #fff;
    span {
      font-size: 42px;
    }
  }
  .money {
    padding: 44px 12px 0;
  }
  .tip {
    font-size: 14px;
    line-height: 20px;
    color: #999;
    margin-bottom: 16px;
  }
  .money-item {
    display: inline-block;
    width: 104px;
    height: 60px;
    line-height: 60px;
    margin: 0 19px 20px 0;
    background: #f2f2f2;
    border-radius: 8px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #666;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  .money-item.active {
    background: #3dc48d;
    color: #fff;
  }
  .bottom {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 12px;
  }
  .user {
    align-items: center;
    padding: 0 0 0 14px;
    font-size: 12px;
    line-height: 17px;
    color: #666;
    margin-bottom: 10px;
    span {
      color: #3dc48d;
    }
  }
  .check {
    margin-right: 4px;
  }
  .btn {
    height: 44px;
    width: 100%;
    background: #3dc48d;
    color: #fff;
    border-radius: 22px;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
  .btn.disable {
    background: #f4f4f4;
    color: #d9d9d9;
  }
  .img-icon,
  .van-checkbox__icon {
    height: 12px;
    width: 12px;
  }
}
</style>